import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import TutorialSider from "../../components/guide/tutorial-sider";

// import css from "../../styles/guide.css";

const Scheduling = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Online Work Scheduler</title>
        <meta
          name="description"
          content="Using the DayViewer Fast Planner to plan and schedule work makes every job easier to see."
        />
      </Helmet>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <TutorialSider />
        <Layout className="guide-body">
          <h1>Work Scheduler</h1>
          <Row>
            <Col>
              <h3>Intro</h3>
              <p>
                If you need to plan, delegate and organize work to your team, it
                can simply be done yourself without any special tools, a
                spreadsheet, or let your staff or colleague know by email or
                verbally.
              </p>
              <p>
                However those methods do not offer a reminder notification which
                would automatically communicate the task or job before the job
                needs to begin, or give the employee/team member the opportunity
                to check further details on that job whenever they need
                reference to. This is where a central calendar planner system
                with task management built in like DayViewer can really come in
                handy.
              </p>
              <p>
                First off, we assume you have gone through the Team Room
                Creation Process already and have a few members in your team
                already, but if not, please find details how to open a team room
                and add members here:{" "}
                <Link to="/guide-content/dayviewer-team">Team Room Guide</Link>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps">
                <h3>Step 1</h3>
                <p>
                  Go into your team room and move straight to the Fast Planner.
                  (Skip to the video in step 4 to see how it all works if you
                  are just checking things out at this stage). Note: Fast
                  Planner works best in desktop mode, but can also work on phone
                  in portrait, it will adapt to the screen.
                </p>
              </div>
              <div className="tutorial-steps">
                <h3>Step 2</h3>
                <p>
                  Create a Task as a Template based on the duration. Here you
                  can see we are creating a "Write Report" task
                </p>
                <div className="tutorial-images">
                  {/* Add an image of the fast planner here */}
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-fast-scheduler-task.png"
                    alt="shift creation"
                    title="Team management area"
                  />
                </div>
              </div>
              <div className="tutorial-steps">
                <h3>Step 3</h3>
                <p>
                  Next we drag that Templated Task into the calendar day boxes.
                  And setup the details of the job that needs to be done and
                  other details.
                </p>
                <div className="tutorial-images">
                  {/* Add an image of the fast planner here */}
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-fast-scheduler-dragtask.png"
                    alt="shift creation"
                    title="Team management area"
                  />
                </div>
              </div>
              <div className="tutorial-steps">
                <h3>Step 4</h3>
                <p>
                  Once task is set, we can now adjust as required. The following
                  video shows the process in full including setting out the week
                  for the team.
                </p>
                <div className="tutorial-images">
                  {/* Make a video of dragging in and adding job details  */}
                  <video
                    preload="none"
                    controls="controls"
                    muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="Team Task Create and Assign"
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/video/fast-planner-scheduling-2-fixed.mp4"
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                Hopefully here you can see it is an easy, quick and efficient
                process to organize shifts and work with flexibility and ease.
              </p>

              <p>
                If you just getting started with DayViewer, please go to our{" "}
                <Link to={`/tutorial-content/quick-start`}>
                  Quick Start tutorial.
                </Link>
              </p>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default Scheduling;
